/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import * as cases from "@whitecobalt/tungsten/utils/case";
import FileKeyLoader from "@whitecobalt/tungsten/FileKeyLoader";
import branding from "@config/branding";
import cookie from "@whitecobalt/tungsten/esm/common/utils/cookie";
import { excludeDuplicate } from "@whitecobalt/tungsten/esm/common/utils/data";
import { useGlobalStoreSelector } from "@services/store/global";
import { useSession } from "@whitecobalt/tungsten/esm/components/Session";
import RevokeImpersonation from "@components/NavigationHeader/UserOffcanvas/RevokeImpersonation";
import OrganisationSelection from "@components/NavigationHeader/UserOffcanvas/OrganisationSelection";
import UserOffcanvas from "@components/NavigationHeader/UserOffcanvas";

export const capitalize = (stringc) => stringc?.trim()?.substring(0, 1)?.toUpperCase() + stringc?.trim()?.substring(1)

export function QuickUser() {
	const history = useHistory();
	const [{user}] = useSession()
	const [menuListLoaded] = useGlobalStoreSelector('menuListLoaded')

	const handleLogout = () => {
		const toggle = document.getElementById("kt_quick_user_toggle");
		if (toggle) {
			toggle.click();
		}
		history.push("/auth/logout");
	};

	const mailTo = "mailto:" + user?.emailAddress;

	return (
		<div
			id="kt_quick_user"
			className="offcanvas offcanvas-right offcanvas p-10"
		>
			<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
			<h3 className="font-weight-bold m-0">
				My Profile
				{/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
			</h3>
			<a
				href="#"
				className="btn btn-xs btn-icon btn-light btn-hover-primary"
				id="kt_quick_user_close"
			>
				<i className="ki ki-close icon-xs text-muted" />
			</a>
			</div>

			<div className="offcanvas-content pr-5 mr-n5">
				<div className="d-flex align-items-center mt-5">
					<div className="symbol symbol-100 mr-5">
						<div className="symbol-label">
							{
							// user.photofile &&
							//   <S3Image fileKey={user?.photofile} className="symbol-label" />
							}
							{!user?.photo ? (
								<div className="font-weight-bold" style={{ fontSize: "4em" }}>
									{cases.capitalize(user?.firstname[0])}
								</div>
							) : (
								<FileKeyLoader fileKey={user.photo} style={{maxHeight: 75, maxWidth: 75}}/>
							)}
						</div>
						{/* <i className="symbol-badge bg-danger" /> */}
					</div>

					<div className="d-flex flex-column">
					<a
						href="/myprofile"
						className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
					>
						{`${user?.title ? `${user?.title}. ` : ''}${capitalize(user?.firstname)} ${capitalize(user?.surname)}`}
					</a>
					<div className="text-muted mt-1"></div>
					<div className="navi mt-2">
						<a href={mailTo} className="navi-item">
						<span className="navi-link p-0 pb-2">
							<span className="navi-icon mr-1">
							<span className="svg-icon-lg svg-icon-primary">
								<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/Communication/Mail-notification.svg"
								)}
								></SVG>
							</span>
							</span>
							<span className="navi-text text-muted text-hover-primary">
							{user?.emailAddress}
							</span>
						</span>
						</a>
					</div>
					{(branding.allowImpersonation && cookie.get(branding.token.name+"OG")) ? (
						<RevokeImpersonation />
					) : (
						<button
							className="btn btn-secondary btn-bold"
							onClick={handleLogout}
						>
							Sign out
						</button>
					)}
					</div>
				</div>
				<div className="separator separator-dashed mt-8 mb-5" />
				{(branding.allowOrganisationSelection && excludeDuplicate(user.organisationIDs).length > 1) && (
					<>
						<OrganisationSelection />
						<div className="separator separator-dashed mt-5" />
					</>
				)}
				<div className="navi navi-spacer-x-0 p-0">
					{menuListLoaded && (<UserOffcanvas />)}
				</div>
			</div>
		</div>
	);
}
