import React from 'react'
import FormManager from "@whitecobalt/tungsten/FormManager";
import SelectDropdown from "@whitecobalt/tungsten/Fields/SelectDropdown";
import { OrganisationsDropdownQuery } from "@services/query/DropdownQueries";
import { SessionActionType, useSession } from '@whitecobalt/tungsten/esm/components/Session';
import { useResetRememberTableState } from '@whitecobalt/tungsten/esm/components/Grid/RememberTableState';

const OrganisationSelection: React.FunctionComponent = () => {
    const [{user, others}, dispatch] = useSession()
    const resetRememberTableStates = useResetRememberTableState()

    const handleChange = (event: any) => {
        const organisationID = event.target.value

        localStorage.setItem('selected-organisation', `${organisationID}`)

        resetRememberTableStates()
        
        dispatch({
            type: SessionActionType.SET_OTHERS,
            payload: {
                ...others,
                organisationID: organisationID,
                hasSelectedOrganisation: true
            }
        })
    };

    return (
        <SelectDropdown.Graph
            gql={OrganisationsDropdownQuery}
            fetchPolicy="cache-first"
            variables={{
                where: {
                    ID: {
                        in: user?.organisationIDs
                    }
                }
            }}
            name="organisationID"
            placeholder="Select Organisation"
            onChange={handleChange}
            value={others?.organisationID}
        />
    )
}

export default OrganisationSelection