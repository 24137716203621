/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { MenuListQuery } from "@services/query/MenuListQuery";
import Facade from "@whitecobalt/tungsten/Facade"
import Collapse from "@whitecobalt/tungsten/Collapse"
import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { emptyArray } from "@whitecobalt/tungsten/utils/assets";
import { createDataChildren } from "@whitecobalt/tungsten/utils/data";
import branding from "@config/branding";
import worldTrigger from "world-trigger";
import { useGlobalStoreSelector } from "@services/store/global";
import './index.scss';

export function AsideMenuList({ layoutProps }) {
  const [, setMenuListLoaded] = useGlobalStoreSelector('menuListLoaded')
	const location = useLocation()

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

	const requestMenu = useQuery(MenuListQuery, {
		fetchPolicy: "no-cache",
		variables: { MenuID: 1 },
	})

	useEffect(() => {
		setMenuListLoaded(!requestMenu.loading)
	}, [requestMenu.data, requestMenu.loading])

	const data = requestMenu.data?.MenuItems?.items || emptyArray

	const dataWithChildren = useMemo(() => {
		return createDataChildren(data, 'ID', 'ParentMenuItemID')
	}, [data])

	if(requestMenu.loading) {
		return (
			new Array(3).fill(0).map((_, index) => (
        <ul key={index} className={`menu-nav ${layoutProps.ulClasses}`}>
          <li className="menu-section ">
            <Facade loading title titleStyle={{height: 13, marginTop: 16}} padding={false} rows={0}/>
          </li>
          {new Array(3).fill(0).map((_, index) => (
            <li key={index}
                className={`menu-item `}
                aria-haspopup="true"
              >
              <a className="menu-link" href="#">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                </span>
                <span className="menu-text"><Facade loading rowListStyle={{margin: 0}} range={[40, 70]} rowStyle={{height: 13}} padding={false} rows={1}/></span>
              </a>
            </li>
          ))}
        </ul>
      ))
		)
	}

  return dataWithChildren.map((each) => {
      const isMenuPopup = branding.menuPopups[each.Title]
      const hasActive = each.children?.some((each) => (location.pathname + (each.URL?.includes('?') ? location.search : '')).startsWith(each.URL || 'no-url'))
      const LinkComponent = each.LaunchExternal || isMenuPopup ? 'a' : NavLink
      
      return (
        <ul key={each.ID} className={`menu-nav ${layoutProps.ulClasses}`}>
          {!each.children ? (
            <li className={classNames("menu-section", {"menu-section-active": location.pathname.startsWith(each.URL)})}>
                <LinkComponent className="menu-link d-flex w-100 h-100" {...({
                      [LinkComponent === 'a' ? 'href' : 'to']: each.URL || "",
                      onClick: isMenuPopup ? (e) => {
                        e.preventDefault()
                        worldTrigger.dispatchTrigger(isMenuPopup)
                      }: undefined
                    })}>
                  <div className="d-flex">
                    {((each.Icon && each.Icon !== 'menu-icon') || branding.menuIcons[each.Title]) && (
                      <span className="svg-icon menu-icon">
                        <i className={`mr-2 ${each.Icon || branding.menuIcons[each.Title]}`}/>
                      </span>
                    )}
                    <h4 className="menu-text">{each.Title}</h4>
                  </div>
                </LinkComponent>
            </li>
          ) : (
            <>
              <Collapse.Target as="li" eventKey={each.Title} className={classNames("menu-section", {"menu-section-active": hasActive})}>
                <div className="d-flex">
                  {((each.Icon && each.Icon !== 'menu-icon') || branding.menuIcons[each.Title]) && (
                    <span className="svg-icon menu-icon">
                      <i className={`mr-2 ${each.Icon || branding.menuIcons[each.Title]}`}/>
                    </span>
                  )}
                  <h4 className="menu-text">{each.Title}</h4>
                </div>
                <i className="menu-icon fa fa-angle-right" aria-hidden="true"/>
              </Collapse.Target>
              <Collapse show={hasActive} persistentShow={hasActive} eventKey={each.Title} transition="fast" accordionKey="aside-menu">
                {each.children.map((each) => {
                  const isMenuPopup = branding.menuPopups[each.Title]
                  const LinkComponent = each.LaunchExternal || isMenuPopup ? 'a' : NavLink
                  return (
                    <li key={each.ID}
                        className={`menu-item ${getMenuItemActive(each.URL || "", false)}`}
                        aria-haspopup="true"
                      >
                      <LinkComponent className="menu-link" {...({
                        [LinkComponent === 'a' ? 'href' : 'to']: each.URL || "",
                        onClick: isMenuPopup ? (e) => {
                          e.preventDefault()
                          worldTrigger.dispatchTrigger(isMenuPopup)
                        }: undefined
                      })}>
                        <span className="svg-icon menu-icon sub-menu-icon">
                          {((each.Icon && each.Icon !== 'menu-icon') || branding.menuIcons[each.Title]) ? (
                            <i className={`${each.Icon || branding.menuIcons[each.Title]}`}/>
                          ) : (
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                          )}
                        </span>
                        <span className="menu-text">{each.Title}</span>
                      </LinkComponent>
                    </li>
                  )
              })}
              </Collapse>
            </>
          )}
        </ul>
      )
    })
}
