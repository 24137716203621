import { toAbsoluteUrl } from "@whitecobalt/tungsten/utils/assets";
import { darkenColor } from "@whitecobalt/tungsten/utils/colors";
import { useSession } from '@whitecobalt/tungsten/Session'
import React, { useLayoutEffect } from 'react'
import { Link } from "react-router-dom";
import './index.scss'
import branding from "@config/branding";

interface AuthContainerProps {
    
}

const AuthContainer: React.FunctionComponent<AuthContainerProps> = ({children}) => {
    const [{others}] = useSession()

    useLayoutEffect(() => {
        const html = document.documentElement
        html.classList.add('auth-page')

        return () => html.classList.remove('auth-page')
    }, [])

    const today = new Date().getFullYear();
    
    return (
        <>
            <div className="d-flex flex-column flex-root">
            {/*begin::Login*/}
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
                    id="whitecobalt-auth">
                    {/*begin::Aside*/}
                    <div
                    className="login-aside bg-primary d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl(branding.background)})`,
                        backgroundPosition: 'top left',
                        backgroundBlendMode: 'multiply',
                    }}
                    >
                    {/*begin: Aside Container*/}
                    <div className="d-flex flex-row-fluid flex-column justify-content-between">
                        {/* start:: Aside header */}
                        <Link to="/" className="flex-column-auto mt-5">
                        <img
                            alt="Logo"
                            className="max-h-70px"
                            src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                        />
                        </Link>
                        {/* end:: Aside header */}
        
                        {/* start:: Aside content */}
                        <div className="flex-column-fluid d-flex flex-column justify-content-center">
                        {/* <h3 className="font-size-h1 mb-5 text-white"> */}
                        <h3 className="font-size-h1 mb-5 text-secondary">
                            Welcome to {branding.brandName}
                        </h3>
                        </div>
                        {/* end:: Aside content */}
        
                        {/* start:: Aside footer for desktop */}
                        <div className='d-none flex-column-auto d-lg-flex justify-content-between mt-10 opacity-80'>
                            <div className="text-dark order-2 order-md-1">
                                {typeof branding.brandingFooter === "string" ? (
                                    <a
                                        href={branding.brandSite}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-dark-75 text-hover-primary"
                                        dangerouslySetInnerHTML={{
                                            __html: branding.brandingFooter
                                        }}
                                    />
                                ) : (
                                    <>
                                        &copy;{" "} <span className="text-muted font-weight-bold mr-2">{`${(parseInt(today.toString()) - 1).toString()}-${today.toString()} `} </span>
                                        <a
                                            href={branding.brandSite}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-secondary text-hover-primary"
                                            >
                                            {branding.brandName}{" "}
                                        </a>
                                        {branding.brandingFooter && (
                                            <>
                                                - Technology provided by{" "}
                                                <a 
                                                    href="https://www.whitecobalt.com"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-secondary text-hover-primary"
                                                    >
                                                    White Cobalt Ltd
                                                </a>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {/* end:: Aside footer for desktop */}
                    </div>
                    {/*end: Aside Container*/}
                    </div>
                    {/*begin::Aside*/}
        
                    {/*begin::Content*/}
                    <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
                    {/*begin::Content header*/}
                    <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                        {/* <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                        <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link> */}
                    </div>
                    {/*end::Content header*/}
        
                    {/* begin::Content body */}
                    <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                        {children}
                    </div>
                    {/*end::Content body*/}
        
                    {/* begin::Mobile footer */}
                    <div
                        className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                        <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                        &copy; {today} {branding.brandName}
                        </div>
                        <div className="d-flex order-1 order-sm-2 my-2">
                        {/* <Link to="/terms" className="text-dark-75 text-hover-primary">
                            Privacy
                            </Link>
                            <Link
                                to="/terms"
                                className="text-dark-75 text-hover-primary ml-4"
                            >
                            Legal
                            </Link>
                            <Link
                                to="/terms"
                                className="text-dark-75 text-hover-primary ml-4"
                            >
                            Contact
                            </Link> */}
                        </div>
                    </div>
                    {/* end::Mobile footer */}
                    </div>
                    {/*end::Content*/}
                </div>
            {/*end::Login*/}
            </div>
      </>
    )
}

export default AuthContainer
