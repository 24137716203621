import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import branding from '@config/branding'
import UserOffcanvasTimeLogs from './TabScreens/TimeLogs'
import UserOffcanvasProfile from "./TabScreens/Profile"
import UserOffcanvasSecurity from "./TabScreens/Security"

const UserOffcanvas: React.FunctionComponent = () => {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        
        const handleToggle = (event: any) => {
            if(event.target.closest("#kt_quick_user_toggle")) {
                setOpen(true)
            } else if(!document.querySelector("#kt_quick_user_close.prevent-close") && event.target.closest("#kt_quick_user_close, .offcanvas-overlay")) {
                setOpen(false)
            }
        }

        document?.addEventListener('click', handleToggle, false)
        
        return () => {
            document?.removeEventListener('click', handleToggle, false)
        }
        
    }, [open])

    return open ? (
        <Tab.Container defaultActiveKey="profile">
            <Nav className="nav-tabs nav-bold nav-tabs-line" defaultActiveKey="profile">
                <Nav.Item className="flex-1">
                    <Nav.Link className="justify-content-center" eventKey="profile">Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item className="flex-1">
                    <Nav.Link className="justify-content-center" eventKey="password">Security</Nav.Link>
                </Nav.Item>
                {branding.timeTracking && (
                    <Nav.Item className="flex-1">
                        <Nav.Link className="justify-content-center" eventKey="time-logs">Time Logs</Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
            <Tab.Content className="ml-1">
                <UserOffcanvasProfile/>
                <UserOffcanvasSecurity/>
                {branding.timeTracking && (
                    <UserOffcanvasTimeLogs />
                )}
            </Tab.Content>
        </Tab.Container>
    ) : null
}

export default UserOffcanvas