import ModalConfirm from "@whitecobalt/tungsten/esm/components/Modal/Confirm";
import { useEffect } from "react";

export const useUserPreventClose = (dirty: boolean) => {
    useEffect(() => {
        const closeEl = document.getElementById('kt_quick_user_close')
        if(!closeEl) return;

        if(dirty) {

            closeEl.classList.add("prevent-close")

            const handler = async () => {
                const confirmed = await ModalConfirm({
                    title: 'Unsaved changes',
                    body: 'You have not save your changes, are you sure you want to continue?'
                })

                if(confirmed) {
                    closeEl.classList.remove("prevent-close")

                    queueMicrotask(() => closeEl.click())
                }
            }

            closeEl.addEventListener("offcanvas.prevent.close", handler)

            return () => closeEl.removeEventListener("offcanvas.prevent.close", handler)
        } 
        
        closeEl.classList.remove("prevent-close")
    }, [dirty])
}