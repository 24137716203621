
import { useQuery, useApolloClient } from '@apollo/client'
import branding from '@config/branding'
import { emptyArray } from '@whitecobalt/tungsten/esm/common/utils/assets'
import Loader from '@whitecobalt/tungsten/esm/components/Loader'
import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { cacheQueries, EntityCachePropType, EntityCachesQuery } from './queries'
import { useGQLCacheManagement } from "@components/CacheGQL/useGQLCacheManagement"

const entityCache = localStorage.getItem('entity-cache')

interface CacheGQLProps extends React.HTMLProps<HTMLButtonElement> {
    
}

const CacheGQL: React.FunctionComponent<CacheGQLProps> = ({
    children
}) => {
    useGQLCacheManagement()
    const [loading, setLoading] = useState(false)
    const [numberOfDone, setNumberOfDone] = useState(0.001)
    const requestCache = useQuery<{EntityCaches: { items: EntityCachePropType[], totalCount: number; }}>(EntityCachesQuery, {
        skip: branding.services.env === 'development' || entityCache === 'off'
    })

    const client = useApolloClient()

    const dataToCache = requestCache.data?.EntityCaches.items || emptyArray

    useEffect(() => {
        const dataToCacheLength = dataToCache.length
        if(dataToCacheLength > 0) {
            setLoading(true)

            let index = 0

            const recursive = async () => {
                const item = dataToCache[index]
                const query = cacheQueries[
                    item.EntityName as keyof typeof cacheQueries
                  ] || []

                try {
                    await Promise.all(query.map((query) =>
                        client
                          .query({
                            query,
                            fetchPolicy: "cache-first",
                          })
                    ))
    
                    setNumberOfDone((prev) => prev + 1)
    
                    if(dataToCacheLength - 1 > index) {
                        index++
                        await recursive()
                    }
                } catch {
                    if(dataToCacheLength - 1 > index) {
                        index++
                        await recursive()
                    }
                }

                setLoading(false)
                setNumberOfDone(dataToCacheLength)
            }
            
            recursive()
        }
    }, [dataToCache])

    const now = dataToCache.length > 0 ? Math.min(100, Math.ceil((numberOfDone/dataToCache.length) * 100)) : 0

    return requestCache.loading || loading ? (
        <Loader active hasSpinner={false} className="kt-splash-screen">
            <div className="text-center mb-4"  data-env-impose="true">
                <img src={branding.logo.splash} style={{maxWidth: 250}}/>
            </div>
            <ProgressBar variant="primary" animated now={now} label={`${now}%`} style={{width: 350}} />
        </Loader>
    ) : <>{children}</>
}

export default CacheGQL
