import { useWorldTrigger } from '@whitecobalt/tungsten/esm/common/hooks/useWorldTrigger';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import worldTrigger from 'world-trigger'
import { convertHoursToReadable } from "@components/NavigationHeader/UserOffcanvas/TabScreens/TimeLogs";
import './index.scss';

export interface StopWatchRef {
    start: () => void;
    end: () => number;
}

interface StopWatchProps {
    defaultActive?: boolean;
    defaultSeconds?: number;
    stripped?: boolean;
}

const StopWatch = forwardRef<StopWatchRef, StopWatchProps>(({defaultActive, defaultSeconds, stripped}, ref) => {
    const [active, setActive] = useState<boolean>(defaultActive!)
    const [timeInSeconds, setTimeInSeconds] = useState<number>(defaultSeconds!)

    useEffect(() => {
        setTimeInSeconds(defaultSeconds!)
        setActive(defaultActive === true)
    }, [defaultSeconds, defaultActive])

    useImperativeHandle(ref, () => ({
        start: () => {
            setActive(true)
        },
        end: () => {
            setActive(false)
            setTimeInSeconds(0)

            return timeInSeconds
        }
    }))

    useEffect(() => {
        if(active) {
            const cleanup = setInterval(() => {
                setTimeInSeconds(prev => {
                    const update = prev + 1
                    worldTrigger.dispatchTrigger('stop.watch.sync', update)
                    return update
                })
            }, 1000)

            return () => clearInterval(cleanup)
        }
    }, [active])

    // const minutes = Math.floor(timeInSeconds/60)

    // const hour = Math.floor(minutes/60)

    // const seconds = timeInSeconds % 60

    return stripped ? (
        <>
            {convertHoursToReadable(timeInSeconds)}
        </>
    ) : (
        <div className="stop-watch">
            <div className="stop-watch-container">
                {convertHoursToReadable(timeInSeconds)}
            </div>
        </div>
    ) 
})

StopWatch.defaultProps = {
    defaultActive: false,
    defaultSeconds: 0
}

export const StopWatchSync: React.FunctionComponent<{defaultSeconds: number}> = ({defaultSeconds}) => {
    const [timeInSeconds, setTimeInSeconds] = useState(defaultSeconds)
    useWorldTrigger('stop.watch.sync', (update) => {
        setTimeInSeconds(update + defaultSeconds)
    }, [defaultSeconds])

    return (
        <>
            {convertHoursToReadable(timeInSeconds)}
        </>
    ) 
}

export default StopWatch