/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { createDataChildren } from "@whitecobalt/tungsten/esm/common/utils/data";
import classNames from "classnames";
import branding from "@config/branding";
import worldTrigger from "world-trigger";
import { useQuery } from "@apollo/client";
import { MenuListQuery } from "@services/query/MenuListQuery";
import { emptyArray } from "@whitecobalt/tungsten/esm/common/utils/assets";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useGlobalStoreSelector } from "@services/store/global";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const [, setMenuListLoaded] = useGlobalStoreSelector('menuListLoaded')

    const requestMenu = useQuery(MenuListQuery, {
        fetchPolicy: "no-cache",
        variables: { MenuID: 1 },
    });

    const data = requestMenu.data?.MenuItems?.items || emptyArray

    const dataWithChildren = useMemo(() => {
        return createDataChildren(data, 'ID', 'ParentMenuItemID')
    }, [data])

    useEffect(() => {
        setMenuListLoaded(!requestMenu.loading)
    }, [requestMenu.data, requestMenu.loading])

    return (
        <div
            id="kt_header_menu"
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {dataWithChildren.map((item) => {
                    const isMenuPopup = branding.menuPopups[item.Title]
                    const LinkComponent = item.LaunchExternal || isMenuPopup ? 'a' : NavLink
                    const hasActive = checkIsActive(location, item.URL)

                    return !item?.children ? ( 
                        <li 
                            key={item.ID}
                            className={classNames("menu-item menu-item-rel", { "menu-item-active": hasActive })} >
                            <LinkComponent exact {...({
                                [LinkComponent === 'a' ? 'href' : 'to']: item.URL || "",
                                target: item.LaunchExternal ? "_blank" : undefined,
                                onClick: isMenuPopup ? (e) => {
                                e.preventDefault()
                                worldTrigger.dispatchTrigger(isMenuPopup)
                                }: undefined
                            })}
                            className="menu-link">
                                <div className="d-flex">
                                    {((item.Icon && item.Icon !== 'menu-icon') || branding.menuIcons[item.Title]) && (
                                    <span className="svg-icon menu-icon">
                                        <i className={`mr-2 ${item.Icon || branding.menuIcons[item.Title]}`}/>
                                    </span>
                                    )}
                                    <span className="menu-text">{item.Title}</span>
                                </div>
                            </LinkComponent>
                        </li>
                    ) : (
                        <li
                            key={item.ID}
                            data-menu-toggle={layoutProps.menuDesktopToggle}
                            aria-haspopup="true"
                            className={classNames("menu-item menu-item-submenu menu-item-rel", { "menu-item-active": hasActive })}>
                            <NavLink className="menu-link menu-toggle" to={item.URL || '/'}>
                                {((item.Icon && item.Icon !== 'menu-icon') || branding.menuIcons[item.Title]) && (
                                    <span className="svg-icon menu-icon">
                                        <i className={`mr-2 ${item.Icon || branding.menuIcons[item.Title]}`}/>
                                    </span>
                                )}
                                <span className="menu-text">{item.Title}</span>
                            </NavLink>
                            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                                <ul className="menu-subnav">
                                    {/*begin::2 Level*/}
                                    {item.children.map((subitem) => {
                                        const isMenuPopup = branding.menuPopups[subitem.Title]
                                        const LinkComponent = subitem.LaunchExternal || isMenuPopup ? 'a' : NavLink

                                        return !subitem.children ? (
                                            <li
                                                key={subitem.ID}
                                                className={classNames("menu-item menu-item-submenu", { "menu-item-active": checkIsActive(location, subitem.URL) })}
                                            >
                                                <LinkComponent {...({
                                                    [LinkComponent === 'a' ? 'href' : 'to']: subitem.URL || "",
                                                    target: subitem.LaunchExternal ? "_blank" : undefined,
                                                    onClick: isMenuPopup ? (e) => {
                                                    e.preventDefault()
                                                    worldTrigger.dispatchTrigger(isMenuPopup)
                                                    }: undefined
                                                })}
                                                className="menu-link">
                                                    {((subitem.Icon && subitem.Icon !== 'menu-icon') || branding.menuIcons[subitem.Title]) && (
                                                        <span className="svg-icon menu-icon">
                                                            <i className={`mr-2 ${subitem.Icon || branding.menuIcons[subitem.Title]}`}/>
                                                        </span>
                                                    )}
                                                    <span className="menu-text">
                                                        {subitem.Title}
                                                    </span>
                                                </LinkComponent>
                                            </li>
                                        ) : (
                                            <li
                                                key={subitem.ID}
                                                className={classNames("menu-item menu-item-submenu", { "menu-item-active": checkIsActive(location, subitem.URL) })}
                                                data-menu-toggle="hover"
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link menu-toggle" to={subitem.URL || 'any'}>
                                                    {((subitem.Icon && subitem.Icon !== 'menu-icon') || branding.menuIcons[subitem.Title]) && (
                                                        <span className="svg-icon menu-icon">
                                                            <i className={`mr-2 ${subitem.Icon || branding.menuIcons[subitem.Title]}`}/>
                                                        </span>
                                                    )}
                                                    <span className="menu-text">
                                                        {subitem.Title}
                                                    </span>
                                                    <i className="menu-arrow" />
                                                </NavLink>
                                                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                                    <ul className="menu-subnav">
                                                        {/*begin::3 Level*/}
                                                        {subitem?.children.map((sub2item) => {
                                                            const isMenuPopup = branding.menuPopups[sub2item.Title]
                                                            const LinkComponent = sub2item.LaunchExternal || isMenuPopup ? 'a' : NavLink
                                                            return (
                                                                <li
                                                                    key={sub2item.ID}
                                                                    className={classNames("menu-item menu-item-submenu", { "menu-item-active": checkIsActive(location, sub2item.URL) })}
                                                                >
                                                                    <LinkComponent {...({
                                                                        [LinkComponent === 'a' ? 'href' : 'to']: sub2item.URL || "",
                                                                        target: sub2item.LaunchExternal ? "_blank" : undefined,
                                                                        onClick: isMenuPopup ? (e) => {
                                                                        e.preventDefault()
                                                                        worldTrigger.dispatchTrigger(isMenuPopup)
                                                                        }: undefined
                                                                    })}
                                                                    className="menu-link">
                                                                        {((sub2item.Icon && sub2item.Icon !== 'menu-icon') || branding.menuIcons[sub2item.Title]) && (
                                                                            <span className="svg-icon menu-icon">
                                                                                <i className={`mr-2 ${sub2item.Icon || branding.menuIcons[sub2item.Title]}`}/>
                                                                            </span>
                                                                        )}
                                                                        <span className="menu-text">
                                                                            {sub2item.Title}
                                                                        </span>
                                                                    </LinkComponent>
                                                                </li>
                                                            )
                                                        })}
                                                        {/*end::3 Level*/}
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                    })}
                                    {/*end::2 Level*/}
                                </ul>
                            </div>
                        </li>
                    )
                })}
            </ul>
            {/*end::Header Nav*/}
        </div>
    )
}
