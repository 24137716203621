/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "@metronic/layout/_core/MetronicLayout";
import { toAbsoluteUrl } from "@metronic/_helpers";
import { PANEL_TOGGLE_ICON, PANEL_REFRESH, PANEL_TITLE } from "@components/NavigationHeader/PanelOffcanvas";

export function QuickPanelToggler() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.quick-panel.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">{PANEL_TITLE}</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
              id="kt_quick_panel_toggle"
              onClick={PANEL_REFRESH}
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                {PANEL_TOGGLE_ICON || (
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}
                  />
                )}
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}
    </>
  );
}
