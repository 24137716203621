import Icon from "@whitecobalt/tungsten/esm/components/Icon"
import React from 'react'
import worldTrigger from "world-trigger"

export const PANEL_TITLE = "Print Queue"

export const PANEL_ICON = (
    <Icon name="print"/>
)

export const PANEL_TOGGLE_ICON = (
    <Icon className="text-primary" name="print"/>
)

export const PANEL_REFRESH = () => worldTrigger.dispatchTrigger("quick.panel.refresh")

export const PANEL_ACTION = (
    <div className="flex-1 px-2">
        <button className="btn btn-xs btn-icon btn-light btn-hover-primary">
            <Icon className="clickable" name="sync" onClick={PANEL_REFRESH}/>
        </button>
    </div>
)

const PanelOffcanvas: React.FunctionComponent = () => {

    return (
        <div className="pt-5">
            Panel
        </div>
    )
}

export default PanelOffcanvas