import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Button from '@whitecobalt/tungsten/Button'
import Toaster from '@whitecobalt/tungsten/Toaster'
import FormManager from '@whitecobalt/tungsten/FormManager'
import { useSession } from '@whitecobalt/tungsten/Session'
import { useAuthAPI } from '@whitecobalt/tungsten/hooks/useAuthAPI'
import branding from '@config/branding'
import MFAActivation from './MFAActivation'
import PasswordField from '@whitecobalt/tungsten/Fields/PasswordField'
import TOTPGoogleAuth from './TOTPGoogleAuth'
import { FormEvent } from "formydable"

const UserOffcanvasSecurity: React.FunctionComponent = () => {
    const [{ user }] = useSession()
    const request = useAuthAPI('/api/ChangePassword')

    const handleSubmit = (event: FormEvent) => {
        const FORM_DATA = event.json()

        const payload = {
            "userID": user?.id,
            "currentPassword": FORM_DATA.currentPassword,
            "newPassword": FORM_DATA.newPassword,
            "confirmPassword": FORM_DATA.confirmPassword
        }

        const toast = Toaster(`Changing Password`)
        request.call({ data: payload }).then((response) => {
            if (response.data.success) {
                toast.success(`Successfully changed Password`)
                event.resetForm()
            } else {
                FormManager.setBackendValidation(event, response.data.errors, {
                    'CurrentPassword': 'currentPassword',
                    'NewPassword': 'newPassword',
                    'ConfirmPassword': 'confirmPassword',
                })

                toast.fail(response?.data?.message || branding.messages.fail)
            }
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message || branding.messages.error
            toast.error(errorMessage);
        })

    }

    return (
        <Tab.Pane eventKey="password">
            <MFAActivation />
            <TOTPGoogleAuth />
            <div className="separator separator-dashed mt-8 mb-5" />
            <FormManager onSubmit={handleSubmit}>
                <FormManager.Input
                    as={PasswordField}
                    formGroupClassName="mb-3"
                    name="currentPassword"
                    label="Current Password"
                    description="Input your Current Password here" />
                <FormManager.Input
                    as={PasswordField}
                    formGroupClassName="mb-3"
                    name="newPassword"
                    label="New Password"
                    description="Input your New Password here" />
                <FormManager.Input
                    as={PasswordField}
                    formGroupClassName="mb-3"
                    name="confirmPassword"
                    label="Confirm New Password"
                    description="Input your Confirm Password here" />
                <div className="d-flex justify-content-end">
                    <Button type="submit" loading={request.loading} variant="primary">
                        Change Password
                    </Button>
                </div>
            </FormManager>
            
        </Tab.Pane>
    )
}

export default UserOffcanvasSecurity