/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import PanelOffcanvas, { PANEL_TITLE, PANEL_ACTION, PANEL_ICON } from "@components/NavigationHeader/PanelOffcanvas";

export function QuickPanel() {
  return (
      <div id="kt_quick_panel" className="offcanvas offcanvas-right offcanvas p-10">
          {/*begin::Header*/}
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">
              {PANEL_ICON} {PANEL_TITLE}
            </h3>
            {PANEL_ACTION}
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
            >
              <i className="ki ki-close icon-xs text-muted" />
            </a>
          </div>
          {/*end::Header*/}

          {/*begin::Content*/}
          <div className="offcanvas-content">
            <div className="navi navi-spacer-x-0 p-0">
            <PanelOffcanvas />
            </div>
          </div>
          {/*end::Content*/}
      </div>
  );
}
