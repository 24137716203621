/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import UserNotification from "@components/NavigationHeader/UserNotification";

export function UserNotificationsDropdown() {
	

	return (
		<UserNotification />
	);
}
