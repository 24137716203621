import { useApolloClient } from "@apollo/client"
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import { useLocalStorage } from "@whitecobalt/tungsten/esm/common/hooks/useLocalStorage"
import { useEffect, useRef } from "react"

export const useGQLCacheManagement = () => {
    const client = useApolloClient()
    const getCacheItems = useAuthAPI<{ cacheItems: Record<string, string> }>('/api/UXCache')
    const { data, setData } = useLocalStorage('cacheItems')

    useEffect(() => {
        const cacheDates = getCacheItems.response?.cacheItems || {}
        
        if(!data) {
            if(Object.keys(cacheDates).length === 0) return;

            setData(cacheDates)
            return;
        }

        const invalidateCaches = Object.keys(cacheDates).filter(key => cacheDates[key] !== data[key]).map(key => {
            return `${key}s`
        })

        if(invalidateCaches.length > 0) {
            invalidateCaches.forEach((entityName) => {
                client.cache.evict({
                    id: "ROOT_QUERY",
                    fieldName: entityName
                })
            })
            setTimeout(() => {
                client.refetchQueries({
                    include: invalidateCaches.map((entityName) => `${entityName}DropdownQuery`),
                })
            }, 1000 * 15)

            setData(cacheDates, true)
        }

    }, [getCacheItems.response?.cacheItems])

    const called = useRef(false)

    useEffect(() => {
        const callback = () => {
            if(!called.current) {
                called.current = true
                getCacheItems.call().finally(() => {
                    called.current = false
                })
            }
        }
        const cleanup = setInterval(callback, 1000 * 10)

        return () => {
            clearInterval(cleanup)
        }
        
    }, [])
}