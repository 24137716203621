import React, {
    useState,
    useMemo,
    useEffect,
    useCallback,
} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import {
    NotificationsQuery,
    NotificationsUpdateQuery,
} from "@services/query/NotificationsQuery";
import { useSession } from "@whitecobalt/tungsten/Session";
import { useHistory } from "react-router-dom";
import { useAuthAPI } from "@whitecobalt/tungsten/hooks/useAuthAPI";
import { useObservable } from "@whitecobalt/tungsten/hooks/useObservable";
import { useLazyQuery } from "@apollo/client";
import Icon from "@whitecobalt/tungsten/Icon";
import classNames from "classnames";
import { dateTimeFormat } from "@whitecobalt/tungsten/utils/dateFormat";
import Facade from "@whitecobalt/tungsten/Facade";
import Badge from "react-bootstrap/Badge";
import branding from "@config/branding";
import { useEffectMounted } from "@whitecobalt/tungsten/esm/common/hooks/useEffectMounted";
import { useGlobalStoreSelector } from "@services/store/global";
import { toAbsoluteUrl } from "@metronic/_helpers";
import { useHtmlClassService } from "@metronic/layout";
import { DropdownTopbarItemToggler } from "@metronic/_partials/dropdowns";

const perPage = 10;

export const UserNotification: React.FunctionComponent = () => {
    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(uiService.config, "extras.notifications.layout") ===
                "offcanvas",
        };
    }, [uiService]);

    const [{ user }] = useSession();
    const [menuListLoaded] = useGlobalStoreSelector('menuListLoaded')
    const history = useHistory();
    const readNotification = useAuthAPI("/api/NotificationRead");
    const readAllNotification = useAuthAPI("/api/NotificationRead/all");
    const [observables] = useObservable({
        page: 1,
        offetNewNotif: 0,
        initializeUnreads: false,
        initializeData: false,
        refetching: false
    })

    const [{ data, loading, totalUnread }, setStates] = useState<{
        data: any[];
        loading: boolean;
        totalUnread: number;
    }>({
        data: [],
        loading: true,
        totalUnread: 0,
    });

    const [loadAllNotif, requestAllNotif] = useLazyQuery(NotificationsQuery, {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        variables: {
            take: perPage,
            skip: 0,
            order: { DateTimeStamp: "DESC" },
            where: {
                UserID: {
                    equals: user?.id,
                },
            },
        },
    });

    const [loadUnreadNotif, requestUnreadNotif] = useLazyQuery(
        NotificationsUpdateQuery,
        {
            fetchPolicy: "no-cache",
            variables: {
                where: {
                    UserID: {
                        equals: user?.id,
                    },
                    IsRead: {
                        equals: false,
                    },
                },
            },
        }
    );

    useEffect(() => {
        if (menuListLoaded) {
            loadUnreadNotif();
            loadAllNotif();
        }
    }, [menuListLoaded]);

    useEffect(() => {
        if (menuListLoaded) {
            const cleanup = setInterval(() => {
                if (requestUnreadNotif?.refetch)
                    requestUnreadNotif?.refetch().then((response) => {
                        const count = response.data?.updates.totalCount || 0;
                        if (count !== totalUnread && count > totalUnread) {
                            observables.offetNewNotif += count - totalUnread
                            requestAllNotif?.refetch?.({
                                take: count - totalUnread,
                                skip: 0
                            }).then((response) => {
                                const newItems = response.data?.Notifications?.items || [];
                                setStates((prev) => ({
                                    ...prev,
                                    data: [...newItems, ...prev.data],
                                    totalUnread: count,
                                }))
                            })
                            const sound = new Audio(branding.notification.pathSoundEffect);
                            sound.play();
                        }
                    });
            }, 10000);

            return () => clearInterval(cleanup);
        }
    }, [menuListLoaded, totalUnread]);

    useEffectMounted(() => {
        if (observables.initializeData === false) {
            observables.initializeData = true
            const items = requestAllNotif.data?.Notifications?.items || [];
            setStates((prev) => ({
                ...prev,
                data: [...prev.data, ...items],
                loading: false
            }));
        }
    }, [requestAllNotif.data]);

    useEffectMounted(() => {
        if (observables.initializeUnreads === false) {
            observables.initializeUnreads = true;
            const totalUnread = requestUnreadNotif.data?.updates.totalCount || 0;
            setStates((prev) => ({
                ...prev,
                totalUnread,
            }));
        }
    }, [requestUnreadNotif.data?.updates.totalCount]);

    const handleSeen = useCallback((itemID) => {
        readNotification
            .call({
                data: {
                    NotificationID: itemID,
                },
            })
            .then(() => {
                if (requestUnreadNotif?.refetch) requestUnreadNotif?.refetch();
            });

        setStates((prev) => ({
            ...prev,
            totalUnread: prev.totalUnread - 1,
            data: prev.data.map((each) => ({
                ...each,
                IsRead: each.IsRead || each.ID === itemID,
            })),
        }));
    }, []);

    const handleScrollEnd = () => {
        if (
            requestAllNotif.data?.Notifications?.pageInfo.hasNextPage &&
            observables.refetching === false
        ) {
            setStates(prev => ({ ...prev, loading: true }))
            observables.refetching = true;
            observables.page += 1;

            requestAllNotif
                ?.refetch?.({
                    take: perPage,
                    skip: observables.offetNewNotif + (perPage * observables.page - perPage),
                })
                .then((response) => {
                    const newItems = response.data?.Notifications?.items || [];
                    setStates((prev) => ({
                        ...prev,
                        data: [...prev.data, ...newItems],
                        loading: false
                    }))
                    observables.refetching = false;
                });
        }
    };

    const hasNotification = data.length > 0

    return (
        <>
            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                        id="kt_quick_notifications_toggle"
                    >
                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
                        </span>
                        <span className="pulse-ring"></span>
                    </div>
                </div>
            )}
            {!layoutProps.offcanvas && (
                <Dropdown drop="down" alignRight>
                    <Dropdown.Toggle
                        as={DropdownTopbarItemToggler}
                        id="kt_quick_notifications_toggle"
                    >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="user-notification-tooltip">
                                    User Notifications
                                </Tooltip>
                            }
                        >
                            <div
                                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                                id="kt_quick_notifications_toggle"
                            >
                                <span className="svg-icon svg-icon-xl svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                                    />
                                </span>
                                {!!totalUnread && (
                                    <>
                                        <Badge variant="danger" className="notification-badge">
                                            {totalUnread}
                                        </Badge>
                                        <span className="pulse-ring"></span>
                                        <span className="pulse-ring" />
                                    </>
                                )}
                            </div>
                        </OverlayTrigger>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                        <div
                            className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                            style={{ backgroundImage: `url(${bgImage})` }}
                        >
                            <div className="position-relative">
                                <h4 className="d-flex flex-center rounded-top mb-10">
                                    <span className="text-white">User Notifications</span>
                                    <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                                        {totalUnread} new
                                    </span>
                                </h4>
                                <Dropdown
                                    className="position-absolute"
                                    style={{ top: 5, right: 5 }}
                                >
                                    <Dropdown.Toggle as="a">
                                        <Icon
                                            name="ellipsis-v"
                                            className="text-center text-muted text-hover-white"
                                            size={20}
                                            style={{ width: 20 }}
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="right">
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                readAllNotification.call().then(() => {
                                                    if (requestUnreadNotif?.refetch)
                                                        requestUnreadNotif?.refetch()
                                                })

                                                setStates((prev) => ({
                                                    ...prev,
                                                    totalUnread: 0,
                                                    data: prev.data.map((each) => ({
                                                        ...each,
                                                        IsRead: true,
                                                    })),
                                                }))
                                            }}
                                        >
                                            Mark all as Read
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="p-5 bg-white">
                                {loading && observables.initializeData === false ? (
                                    new Array(3).fill(0).map((_, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={classNames("notification-item", {
                                                    read: true,
                                                })}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-column font-weight-bold w-100">
                                                        <a
                                                            href="#"
                                                            className="text-dark text-hover-primary mb-1 font-size-lg notification-title"
                                                        >
                                                            <div>
                                                                <Facade
                                                                    loading
                                                                    padding={false}
                                                                    rows={0}
                                                                    title
                                                                />
                                                            </div>
                                                        </a>
                                                        <span className="text-muted">
                                                            <Facade loading padding={false} rows={2} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : hasNotification ? (
                                    <PerfectScrollbar
                                        options={{
                                            wheelSpeed: 2,
                                            wheelPropagation: false,
                                        }}
                                        className="scroll pr-7 mr-n7"
                                        onYReachEnd={handleScrollEnd}
                                        style={{
                                            maxHeight: "300px",
                                            position: "relative",
                                            height: "300px",
                                        }}
                                    >
                                        {data.map((item, index) => (
                                            <div
                                                key={index}
                                                className={classNames("notification-item", {
                                                    read: item.IsRead,
                                                })}
                                                onClick={() => {
                                                    if (item.IsRead === false) {
                                                        handleSeen(item.ID)
                                                    }
                                                    const thepathname = (item.Link || "")?.replace(
                                                        window.location.origin,
                                                        ""
                                                    )
                                                    history.push(
                                                        `${/^\//.test(thepathname) === false ? "/" : ""
                                                        }${thepathname}`
                                                    )
                                                }}
                                            >
                                                {!item.IsRead && (
                                                    <div
                                                        className="notification-actions"
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <Dropdown className="notification-actions-dropdown">
                                                            <Dropdown.Toggle as="a">
                                                                <Icon name="ellipsis-h" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        e.preventDefault()
                                                                        handleSeen(item.ID)
                                                                    }}
                                                                >
                                                                    Mark as Read
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                )}
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-column font-weight-bold w-100">
                                                        <div className="text-dark text-hover-primary mb-1 font-size-lg notification-title">
                                                            <section className="d-flex justify-content-between align-items-start w-100">
                                                                <div>{item.Title}</div>{" "}
                                                                <span className="badge badge-light fs-8">
                                                                    {dateTimeFormat(item.DateTimeStamp, "en-GB")}
                                                                </span>
                                                            </section>
                                                        </div>
                                                        <span className="text-muted">
                                                            {item.Description}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {loading && "Loading More"}
                                        {loading &&
                                            new Array(perPage).fill(0).map((_, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={classNames("notification-item", {
                                                            read: true,
                                                        })}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex flex-column font-weight-bold w-100">
                                                                <a
                                                                    href="#"
                                                                    className="text-dark text-hover-primary mb-1 font-size-lg notification-title"
                                                                >
                                                                    <div>
                                                                        <Facade
                                                                            loading
                                                                            padding={false}
                                                                            rows={0}
                                                                            title
                                                                        />
                                                                    </div>
                                                                </a>
                                                                <span className="text-muted">
                                                                    <Facade loading padding={false} rows={2} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </PerfectScrollbar>
                                ) : (
                                    <div className="notification-no-record">
                                        <Icon name="bell-slash" />
                                        <p>No Notifications</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    )
}

export default UserNotification