import React, { useEffect, useState, useMemo, useRef } from "react";
import clsx from "clsx";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import { QUICK_SEARCH_ICON, QUICK_SEARCH_TIMEOUT, QUICK_SEARCH_TITLE, QUICK_SEARCH_TOGGLE_ICON, useQuickSearch } from "@components/NavigationHeader/QuickSearch";
import QuickSearchResult from "@components/NavigationHeader/QuickSearch/Result";

export function SearchDropdown() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigation, setNavigation] = useState(-1);
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = useQuickSearch();

  const handleSearchChange = (event) => {
    setData(null);
    setSearchValue(event.target.value);
  };

  const clear = () => {
    setData(null);
    setSearchValue("");
  };

  useEffect(() => {
    if (searchValue.length > 0) {

      const cleanup = setTimeout(() => {
        setLoading(true);
  
        handleSearch(searchValue).then(setData).finally(() => setLoading(false))
      }, QUICK_SEARCH_TIMEOUT)

      return () => clearTimeout(cleanup)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const togglerRef = useRef(null)

  useEffect(() => {
    window.onkeydown = (e) => {
        if((e.metaKey || e.ctrlKey) && e.code === "KeyF") {
          togglerRef.current?.click()
          return false;
        }
    }

    return () => {
        window.onkeydown = null
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const handleNavigation = (event) => {
    const isArrowUp = 38
    const isArrowDown = 40
    const isEnter = 13 
    const isESC = 27
    const isBackSpace = event.which === 8
    const target = event.target
    const resultContainer = target.closest('#kt_quick_search_dropdown').querySelector('.quick-search-result')
    const scrollContainer = resultContainer.parentElement
    const items = Array.from(resultContainer.children)
    const optionLength = items.length
    
    switch (event.which) {
      case isArrowUp:
      case isArrowDown:
      event.preventDefault()
      const previousNavigation = navigation
      const hasNavigation = previousNavigation !== -1
      const currentNavigation = hasNavigation 
      ? event.which === isArrowDown 
          ? Math.min(previousNavigation + 1, optionLength) 
          : Math.max(optionLength < previousNavigation ? optionLength : previousNavigation - 1, 0)
      : 0
      const optHeight = items[currentNavigation].offsetHeight || 0
      const navigator_pos = items.slice(0, currentNavigation).reduce((result, item) => {
        return result + item.offsetHeight
      }, 0)
      const scrollTop = event.which === isArrowDown 
          ?  navigator_pos - (scrollContainer.offsetHeight - optHeight) 
          : navigator_pos
          
      if(event.which === isArrowDown ? scrollContainer.scrollTop < scrollTop : scrollContainer.scrollTop > scrollTop)
          scrollContainer.scrollTop = scrollTop
      
      setNavigation(currentNavigation)
      break;
      case isEnter:
        event.preventDefault()
        items[navigation]?.click()
        setNavigation(-1)
        break;
      case isESC:
          setNavigation(-1)
          break;
      default:
          break;
    }
}

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="search-panel-tooltip">{QUICK_SEARCH_TITLE}</Tooltip>}
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_search_toggle"
            >
              {QUICK_SEARCH_TOGGLE_ICON}
            </div>
          </OverlayTrigger>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown
          alignRight
          drop="down"
          onToggle={() => {
            setData(null);
            setLoading(false);
            setSearchValue("");
            setShow(prev => !prev)
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle ref={togglerRef} as={DropdownTopbarItemToggler}>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="search-panel-tooltip">{QUICK_SEARCH_TITLE}</Tooltip>}
            >
              <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                {QUICK_SEARCH_TOGGLE_ICON}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": data && data.length,
              })}
            >
              <form className="quick-search-form">
                <div className="input-group">
                  <div className={`input-group-prepend`}>
                    <span className="input-group-text">
                      {QUICK_SEARCH_ICON}
                    </span>
                  </div>
                  {show && (
                    <input
                      type="text"
                      autoFocus={true}
                      placeholder="Search..."
                      value={searchValue}
                      onChange={handleSearchChange}
                      onKeyDown={handleNavigation}
                      className="form-control"
                    />
                  )}
                  <div
                    className={`input-group-append ${
                      loading ? "spinner spinner-sm spinner-primary" : ""
                    }")}`}
                  >
                    <span className="input-group-text">
                      <i
                        style={{
                          display:
                            loading || !searchValue
                              ? "none"
                              : "flex",
                        }}
                        onClick={clear}
                        className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>
              <QuickSearchResult data={data} navigation={navigation} />
              {/* <SearchResult data={data} /> */}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
