import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import Loader from "@whitecobalt/tungsten/Loader";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import branding from "@config/branding";
import {
    Users,
    RegisteredUsers,
    SentEmails,
    SentSMS,
	SystemSettings,
	Companies,
	Reports,
	EventTypes,
	Events,
	CaseWorks,
	WaitingLists,
	Referrals,
	AvailableTags,
	AvailableTagGroups,
	DocumentTypes,
	Widgets,
	Dashboard,
	Medications,
	Programs,
	PermissionPresets,
	Invoices,
	Bills,
	DocumentTemplates,
	EmailTemplates,
	SMSTemplates,
	PaymentAccounts,
	PaymentRuns,
	TimeLogs,
	Budget,
	BackgroundProcesses,
	DisconnectedProcesses
} from './pages'
import AuthRoutes from "./pages/Auth";
import { ErrorPage } from "./pages/ErrorPage";
import { ContentRoute, Layout } from "@metronic/layout";
import FinalAssessment from "./pages/FinalAssessment";
import CacheGQL from "@components/CacheGQL";
import { useHasMFAPrompt } from "@services/store/global";

const MetronicRoute: any = ContentRoute

const Routes = () => {
	const [{ loading, user, others }, dispatch] = useSession();
	const [hasMFAPrompt] = useHasMFAPrompt()
	const hasAuthenthication = !!(
		user &&
		(branding.allowOrganisationSelection === false ||
		(branding.allowOrganisationSelection &&
			others.hasSelectedOrganisation)) &&
		hasMFAPrompt
	)

	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname
			})
		}
	}

	if(loading) {
		return (
			<Loader active hasSpinner={false} className="kt-splash-screen">
				<div className="mb-4"  data-env-impose="true">
					<img src={branding.logo.splash} style={{maxWidth: 250}}/>
				</div>
				<Spinner animation="border" />
			</Loader>
		)
	}

	return (
		<Switch>
			<Route path="/referral-form">
				<FinalAssessment />
			</Route>
			<Route path="/auth">
				<AuthRoutes />
			</Route>
			<ConditionalRoute
				condition={hasAuthenthication}
				onRedirect={handleRedirect}
				redirectTo='/auth/login'
				path="/">
				<CacheGQL>
					<Layout>
						<ErrorBoundary>
							<Suspense fallback={(<Loader active />)}>
								<Switch>
									<MetronicRoute path="/users" component={Users} />
									<MetronicRoute path="/registered-users" component={RegisteredUsers} />
									<MetronicRoute path="/comms/emails" component={SentEmails} />
									<MetronicRoute path="/comms/sms" component={SentSMS} />
									<MetronicRoute path="/syssettings" component={SystemSettings} />
									<MetronicRoute path="/companies" component={Companies} />
									<MetronicRoute path="/reports" component={Reports} />
									<MetronicRoute path="/widgets" component={Widgets} />
									<MetronicRoute path="/dashboard" component={Dashboard} />
									<MetronicRoute path="/admin/permission-presets" component={PermissionPresets} />
									<MetronicRoute path="/admin/invoices" component={Invoices} />
									<MetronicRoute path="/admin/bills" component={Bills} />
									<MetronicRoute path="/admin/document-templates" component={DocumentTemplates} />
									<MetronicRoute path="/admin/email-templates" component={EmailTemplates} />
									<MetronicRoute path="/admin/sms-templates" component={SMSTemplates} />
									<MetronicRoute path="/admin/payment-accounts" component={PaymentAccounts} />
									<MetronicRoute path="/admin/payment-runs" component={PaymentRuns} />
									<MetronicRoute path="/time-logs" component={TimeLogs} />
									<MetronicRoute path="/budget/actual" component={Budget} />
									<MetronicRoute path="/budget" component={Budget} />
									<MetronicRoute path="/admin/background-process" component={BackgroundProcesses} />
									<MetronicRoute path="/admin/disconnected-jobs" component={DisconnectedProcesses} />
									{/** Custom Pages - Start */}
									{/* <MetronicRoute path="/members" component={Members} /> */}
									<MetronicRoute path="/eventtypes" component={EventTypes} />
									<MetronicRoute path="/events" component={Events} />
									<MetronicRoute path="/caseworks" component={CaseWorks} />
									<MetronicRoute path="/waitinglists" component={WaitingLists} />
									<MetronicRoute path="/referrals" component={Referrals} />
									<MetronicRoute path="/available-tags" component={AvailableTags} />
									<MetronicRoute path="/available-tag-groups" component={AvailableTagGroups} />
									<MetronicRoute path="/document-types" component={DocumentTypes} />
									<MetronicRoute path="/medications" component={Medications} />
									<MetronicRoute path="/members/programs/hiv/children" component={Programs} />
									<MetronicRoute path="/members/programs/hiv/base" component={Programs} />
									<MetronicRoute path="/members/programs/hiv/teens" component={Programs} />
									<MetronicRoute path="/members/programs/hiv/young-adults" component={Programs} />
									<MetronicRoute path="/members/programs/hiv/adults" component={Programs} />
									<MetronicRoute path="/members/programs/adoption-program/children" component={Programs} />
									<MetronicRoute path="/members/programs/adoption-program/teens" component={Programs} />
									<MetronicRoute path="/members/programs/adoption-program/parents" component={Programs} />
									<MetronicRoute path="/members/programs/yana/yana" component={Programs} />
									<MetronicRoute path="/members/programs/yana/brave-together" component={Programs} />
									<MetronicRoute path="/members/programs/others/brave" component={Programs} />
									<MetronicRoute path="/members/programs/others/mindset" component={Programs} />
									<MetronicRoute path="/members/programs/all" component={Programs} />
									{/** Custom Pages - End */}
									<Redirect path="/" exact to={branding.landingPath} />
									<Route path="*" component={ErrorPage}/>
								</Switch>
							</Suspense>
						</ErrorBoundary>
					</Layout>
				</CacheGQL>
			</ConditionalRoute>
			<Route path="*" component={ErrorPage}/>
		</Switch>
	);
}

export default Routes