import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import ModalForm from "@whitecobalt/tungsten/Modal/Form";
import FormManager from "@whitecobalt/tungsten/FormManager";
import { useAuthAPI } from "@whitecobalt/tungsten/hooks/useAuthAPI";
import { useAuthParamAPI } from "@whitecobalt/tungsten/hooks/useAuthParamAPI";
import Toaster from "@whitecobalt/tungsten/Toaster";
import { SessionActionType, useSession } from "@whitecobalt/tungsten/Session";
import OTP from "@whitecobalt/tungsten/Fields/OTP";
import Spinner from 'react-bootstrap/Spinner';
import PasswordField from '@whitecobalt/tungsten/esm/components/Fields/PasswordField';
import branding from '@config/branding';
import Button from "@whitecobalt/tungsten/esm/components/Button";
import SelectDropdown from "@whitecobalt/tungsten/esm/components/Fields/SelectDropdown";
import { MobileCountriesDropdownQuery } from "@services/query/DropdownQueries";

interface MFAActivationProps {
	isButton?: boolean;
}

const MFAActivation: React.FunctionComponent<MFAActivationProps> = ({ isButton }) => {
    const requestEnableMFA = useAuthAPI('/api/EnableMFA')
	const requestDisableMFA = useAuthAPI('/api/DisableMFA')
	const requestVerifyEnableMFA = useAuthParamAPI('/api/VerifyEnableMFA?MFACode={code}', { method: 'POST' })
	const [{user}, dispatch] = useSession()
	const isEnableMFA = user?.loginMFAEnabled

	const handleToggleMFA = (event: any) => {
		const isEnabled = event.target.checked || isButton

		if (isEnabled) {
			const toast = Toaster('Enabling MFA')
			requestEnableMFA.call({
				data: {
					applicationAreaID: 1
				}
			})
			.then((response) => {
				if(response.data.success) {
					handleEnableMFA()
					toast.success(response.data.message)
				} else {
					toast.fail(response.data.message)
				}
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message || branding.messages.error
				toast.error(errorMessage)
			})
		} else {
			handleDisableMFA()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	const handleDisableMFA = () => {
		const recursiveModal = () => {
			ModalForm({
				key: 'disable-mfa',
				title: 'Disable MFA',
				titleIcon: 'fa fa-sms',
				submit: 'Disable MFA',
				body: (
				<>
					<p className="mb-5 text-center">Please enter your Password to Disable MFA</p>
					<FormManager.Input as={PasswordField} name="password" placeholder="Enter your password" formGroupClassName="mb-0"/>
				</>
				)
			})
			.then((event) => {
				if(event.isSubmit) {
					const FORM_DATA = event.json()
					const toast = Toaster('Disabling MFA')

					requestDisableMFA.call({
						data: {
							password: FORM_DATA.password
						}
					})
					.then((response) => {
						if(response.data.success) {
							event.closeModal()
							updateUser()
							toast.success('Successfully disabled MFA')
						} else {

							toast.fail()

							event.setFieldError('password', response.data.message)

							recursiveModal()
						}
					})
					.catch((error) => {
                        const errorMessage = error?.response?.data?.message || branding.messages.error
						toast.error(errorMessage)
						event.setError(errorMessage)
						recursiveModal()
					})
					.finally(() => {
						event.removeLoading()
					})
				}
			})
		}

		recursiveModal()
	}

	const handleEnableMFA = () => {
		const recursiveModal = () => {
			ModalForm({
				key: 'one-time-pass',
				title: 'MFA Verification',
				titleIcon: 'fa fa-sms',
				submit: 'Verify',
				body: (
				<>
					<h3 className="text-center">One-Time Password</h3>
					<p className="mb-5 text-center">Please enter the code send to your phone by SMS</p>
					<FormManager.Input name="code" as={OTP} codeLength={6} formGroupClassName="mb-0" />
				</>
				)
			})
			.then((event) => {
				if(event.isSubmit) {
					const FORM_DATA = event.json()
					const toast = Toaster('Enabling MFA')

					requestVerifyEnableMFA.call({ 
						code: FORM_DATA.code
					})
					.then((response) => {
						if(response.data.success) {
							event.removeLoading()
							event.closeModal()
							updateUser()
							toast.success('Successfully enabled MFA')
						} else {
							event.removeLoading()

							toast.fail()

							event.setFieldError('code', response.data.message)

							recursiveModal()
						}
					})
					.catch((error) => {
                        const errorMessage = error?.response?.data?.message || branding.messages.error
						toast.error(errorMessage)
						event.setError(errorMessage)
					})
				}
			})
		}

		recursiveModal()
	}

	const updateUser = () => {
		dispatch({ 
            type: SessionActionType.SET_USER,
            payload: {
                ...user,
                loginMFAEnabled: !user?.loginMFAEnabled
            }
        })
    }

	const requestUpdateProfile = useAuthAPI('/api/SaveUser')
	const handleClick = () => {
		const recursiveModal = () => {
			ModalForm({
				key: 'update-phone',
				title: 'Enable MFA',
				titleIcon: 'fa fa-mobile-alt',
				submit: 'Next',
				body: (
					<>
						<p className="mb-10">To enable Multi-Factor Authentication we will send a code to your mobile phone. You will need to enter that code on the next screen.</p>
						<FormManager.Input
							prepend={(
								<FormManager.Input
									as={SelectDropdown.Graph}
									gql={MobileCountriesDropdownQuery}
									fetchPolicy="cache-first"
									variables={{
										order: { ID: "ASC" }
									}}
									name="mobileCountryID"
									stripped
									clearable={false}
									value={user?.mobileCountryID || 1}
									manageOptions={(item: any) => ({ ...item, label: `+${item.label}` })}
								/>
							)}
							mutateOnChange={(event: any) => {
								return {
									name: event.target.name,
									value: event.target.value.replace(/[\D]/g, '')
								}
							}}
							maxLength={11}
							name="mobileNumber"
							label="Mobile Number"
							value={(user?.mobileNumber || '').length === 11 ? user?.mobileNumber : (user?.mobileNumber || '').length === 10 ? user?.mobileNumber!.substr(0, 4) + " " + user?.mobileNumber!.substr(4) : undefined}
						/>
					</>
				)
			})
			.then((event) => {
				if(event.isSubmit) {
					const FORM_DATA = event.json()
					const payload = {
						original: {
							"id": user?.id,
						},
						new: {
							"id": user?.id,
							"title": user?.title,
							"firstname": user?.firstname,
							"surname": user?.surname,
							"emailAddress": user?.emailAddress,
							"jobTitle": user?.jobTitle,
							"phoneNumber": user?.phoneNumber,
							"mobileNumber": FORM_DATA?.mobileNumber,
							"mobileCountryID": FORM_DATA?.mobileCountryID || 1,
						},
					}
			
					const toast = Toaster(`Updating Profile`)
					requestUpdateProfile.call({ data: payload }).then((response) => {
						if (response.data.success) {
							toast.void()
							dispatch({
								type: SessionActionType.SET_USER,
								payload: { ...user, ...payload.new }
							})
							handleToggleMFA({target: { checked: true }})
							event.closeModal()
						} else {
							FormManager.setBackendValidation(event, response.data.errors)
							toast.fail(response?.data?.message || branding.messages.fail)
							event.removeLoading()
							recursiveModal()
						}
					})
					.catch((error) => {
						const errorMessage = error?.response?.data?.message || branding.messages.error
						toast.error(errorMessage);
						event.removeLoading()
						recursiveModal()
					})
				}
			})
		}

		recursiveModal()
	}

	if(!user?.applicationAreas[0].enableMFA) {
		return null
	}

	if(isButton) {
		return (
			<Button type="button" onClick={handleClick} block className="justify-content-center mb-3">Enable MFA</Button>
		)
	}
    
    return (
        <Form.Group className="d-flex align-items-center">
			<Form.Check 
				type="switch"
				id="mfa-switch"
				checked={isEnableMFA}
				label="Enable MFA"
				disabled={requestEnableMFA.loading}
				onChange={handleToggleMFA}/>
        </Form.Group>
    )
}

export default MFAActivation